<template>
  <div class="about">
    <h1>This is an about page</h1>
      <button @click="goBack()">Previous page</button>
  </div>
</template>
<script>

export default {
  name: 'About',
  methods: {
    goBack() {
      this.$store.commit("setPageTransition", "back");
      this.$router.push({
        name: "Home"
      });
    }
  }
};
</script>